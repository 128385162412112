/* src/components/Sidebar.css */

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.sidebar-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 0 auto 20px;
  border-radius: 10px;
}

.sidebar-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
}

.sidebar-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #D4A539;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Orbitron', sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-button:hover {
  background-color: #C4972C;
  transform: scale(1.05);
}

.sidebar-button:focus {
  outline: 2px solid #E97451;
  outline-offset: 2px;
}

@media (max-width: 1023px) {
  .sidebar {
    padding-top: 60px; /* Make room for the hamburger button */
  }
}

@media (min-width: 1024px) {
  .sidebar {
    padding-top: 20px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .sidebar-button {
    transition: none;
  }
}