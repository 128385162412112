.message-input {
  position: relative;
  width: 100%;
  height: 50x;
  background-color: #1C245C;
  border-top: 0px solid #2A3166;
  border-radius: 15px;
  overflow: visible;
}

.record-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E97451;
  border: none;
  color: white;
  font-size: 16px;
  font-family: 'Orbitron', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  margin: -0px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
}

.record-button:hover {
  background-color: #d86941;
}

.record-button:active {
  background-color: #c75e3a;
}

.record-button.recording {
  background-color: #c75e3a;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* Ensure text doesn't overflow on very small screens */
.record-button span {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 1.2;
}

/* Waveform Animation */
@keyframes waveform {
  0% { height: 5px; }
  50% { height: 20px; }
  100% { height: 5px; }
}

.waveform {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 10px;
}

.waveform-bar {
  width: 3px;
  height: 5px;
  background-color: white;
  margin: 0 1px;
  border-radius: 1px;
}

.record-button.recording .waveform-bar {
  animation: waveform 0.5s ease-in-out infinite;
}

.record-button.recording .waveform-bar:nth-child(2) { animation-delay: 0.1s; }
.record-button.recording .waveform-bar:nth-child(3) { animation-delay: 0.2s; }
.record-button.recording .waveform-bar:nth-child(4) { animation-delay: 0.3s; }
.record-button.recording .waveform-bar:nth-child(5) { animation-delay: 0.4s; }

/* Pulsing Frame Animation */
@keyframes pulse-frame {
  0% { box-shadow: 0 0 0 0 rgba(212, 165, 57, 0.7); }
  70% { box-shadow: 0 0 0 5px rgba(212, 165, 57, 0.7); }
  100% { box-shadow: 0 0 0 0 rgba(212, 165, 57, 1.0); }
}

.record-button.recording {
  animation: pulse-frame 2s infinite;
}

/* Mobile styles (up to 480px) */
@media (max-width: 480px) {
  .message-input {
    height: auto;
    
  }

  

  .waveform {
    margin-left: 0;
    margin-top: 5px;
  }
}

/* Tablet styles (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .message-input {
    height: 50px;
  }

  .record-button {
    font-size: 15px;
  }
}

/* Desktop styles (769px and above) */
@media (min-width: 769px) {
  .message-input {
    height: 50px;
  }

  .record-button {
    font-size: 16px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .record-button {
    transition: none;
  }

  .record-button.recording {
    animation: none;
  }

  .record-button.recording .waveform-bar {
    animation: none;
    height: 10px;
  }
}

/* High contrast mode */
@media (forced-colors: active) {
  .record-button {
    border: 2px solid ButtonText;
  }
}