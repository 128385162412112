/* src/components/InstructionsModal.css */
:root {
 
  --max-desktop-width: 2250px;
}

.instructions-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 36, 92, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .instructions-modal-content {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    width: 90%;  
    height: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    color: white;
    position: relative;
    font-family: 'Roboto', sans-serif;
  }
  
  .instructions-modal-content h2 {
    text-align: center;
    color: #D4A539;
    font-family: 'Orbitron', sans-serif;
    font-size: clamp(1.5rem, 4vw, 2rem);
    margin-bottom: 15px;
  }
  
  .instructions-modal-content h3 {
    color: #D4A539;
    font-family: 'Orbitron', sans-serif;
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .instructions-text {
    font-family: 'Roboto', sans-serif;
    text-align: justify;
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    line-height: 1.6;
  }
  
  .instructions-text ul {
    padding-left: 20px;
  }
  
  .instructions-text li {
    margin-bottom: 10px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: clamp(8px, 2vw, 12px) clamp(12px, 3vw, 18px);
    background: #E97451;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Orbitron', sans-serif;
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    transition: background-color 0.3s ease;
  }
  
  .close-button:hover {
    background-color: #d86941;
  }
  
  /* Mobile styles (up to 767px) */
  @media (max-width: 767px) {
    .instructions-modal-content {
      padding: 15px 15px 15px;
    }
  
    .close-button {
      top: 5px;
      right: 5px;
    }
  }
  
  /* Tablet styles (768px to 1023px) */
  @media (min-width: 768px) and (max-width: 1023px) {
    .instructions-modal-content {
      padding: 25px;
      width: 90%;
      max-width: none;
    }
  }
  
  /* Desktop styles (1024px and above) */
  @media (min-width: 1024px) {
    .instructions-modal-content {
      padding: 30px;
      width: 90%;
      max-width: 1600px;
      
      

    }
  }
  
  /* Accessibility */
  @media (prefers-reduced-motion: reduce) {
    .close-button {
      transition: none;
    }
  }