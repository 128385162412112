/* src/components/WisdomMapModal.css */

.wisdom-map-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 36, 92, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.wisdom-map-content {
  background: rgba(28, 36, 92, 0.8);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.wisdom-map-content h2 {
  margin: 0 0 10px 0;
  text-align: center;
  font-size: 1.5em;
  font-family: 'Orbitron', sans-serif;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  padding: 10px;
  background: rgba(233, 116, 81, 0.2);
  border: 1px solid #E97451;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.custom-dropdown:hover .dropdown-header {
  background: rgba(233, 116, 81, 0.3);
}

.figure-dropdown .dropdown-header {
  background: rgba(212, 165, 57, 0.2);
  border: 1px solid #D4A539;
}

.figure-dropdown:hover .dropdown-header {
  background: rgba(212, 165, 57, 0.3);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(28, 36, 92, 0.3);
  border: 1px solid #E97451;
  border-top: none;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.custom-dropdown.open .dropdown-list {
  max-height: 200px;
  overflow-y: auto;
}

.figure-dropdown .dropdown-list {
  border-color: #D4A539;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  background:  rgba(28, 36, 92, 0.9);
}

.dropdown-item:hover {
  background: rgba(233, 116, 81, 0.9);
}

.figure-dropdown .dropdown-item:hover {
  background: rgba(212, 165, 57, 0.9);
}

.map-container {
  flex-grow: 1;
  position: relative;
  background-size: cover;
  border-radius: 10px;
  margin: 10px 0;
  height: 60%;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.star:not(.gathered) {
  opacity: 0.3;
}

.star.gathered {
  background-color: #D4A539;
  box-shadow: 0 0 10px 2px #D4A539, 0 0 20px 5px rgba(212, 165, 57, 0.5);
  animation: pulsate 2s infinite;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 10px 2px #D4A539, 0 0 20px 5px rgba(212, 165, 57, 0.5);
  }
  50% {
    box-shadow: 0 0 15px 3px #D4A539, 0 0 25px 7px rgba(212, 165, 57, 0.7);
  }
  100% {
    box-shadow: 0 0 10px 2px #D4A539, 0 0 20px 5px rgba(212, 165, 57, 0.5);
  }
}

.star:hover {
  transform: scale(1.5);
}

.star-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 2;
}

.star:hover .star-tooltip {
  visibility: visible;
  opacity: 1;
}

.seed-details {
  margin-top: 0px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  padding: 0px;
  border-radius: 0dvh;
}

.progress-bar {
  width: 100%;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-top: 15px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #D4A539;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.progress-bar.overall .progress {
  background-color: #E97451;
}

.close-button {
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  background: #E97451;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #d86941;
}

@media (max-width: 768px) {
 

  .close-button {
    top: 5px;
    right: 5px;
    font-size: 12px;
    padding: 3px 8px;
  }
}
  
/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .wisdom-map-content {
    width: 100%;
    height: 90%;
  }

  .dropdown-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .custom-dropdown {
    width: 48%;
  }

  .map-container {
    height: 70%;
  }

  .star {
    width: 20px;
    height: 20px;
  }

  .star-tooltip {
    font-size: 14px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .wisdom-map-content {
    width: 100%;
    height: 100%;
    padding: 30px;
  }

  .wisdom-map-content h2 {
    font-size: 2em;
  }

  .dropdown-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .custom-dropdown {
    width: 48%;
  }

  .map-container {
    height: 70%;
  }

  .star {
    width: 25px;
    height: 25px;
  }

  .star-tooltip {
    font-size: 16px;
  }

  .seed-details {
    font-size: 1.1em;
  }

  .close-button {
    font-size: 1.1em;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .star,
  .dropdown-list,
  .progress,
  .close-button,
  .star-tooltip {
    transition: none;
  }

  .star.gathered {
    animation: none;
  }
}