:root {
  --primary-color: #1C245C;
  --secondary-color: #D4A539;
  --accent-color: #E97451;
  --text-color: white;
  --max-desktop-width: 2250px;
}

.figure-carousel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 36, 92, 1.00);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
  color: var(--text-color);
}

.categories-section {
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: rgba(28, 36, 92, 0.9);
}

.category-button {
  background-color: transparent;
  color: var(--text-color);
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-button.active {
  border-bottom: 2px solid var(--secondary-color);
  font-weight: bold;
}

.miniatures-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  background-color: rgba(28, 36, 92, 0.8);
}

.miniature {
  width: 60px;
  height: 60px;
  margin: 5px;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.miniature img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.miniature.active {
  border: 2px solid var(--accent-color);
  transform: scale(1.1);
}

.main-image-section {
  flex-grow: 1;
  position: relative;
}

.figure-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(28, 36, 92, 0.7);
  padding: 15px;
  text-align: center;
}

.info-overlay h2 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center; /* Ensure the figure name is centered */
}

.info-overlay p {
  margin-top: 10px;
  font-size: 1rem;
  text-align: justify; /* Keep longer descriptions justified */
}

.actions-section {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: rgba(28, 36, 92, 0.9);
}

.actions-section button {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actions-section button:hover {
  background-color: #C4972C;
}

.actions-section .select-button {
  background-color: var(--accent-color);
}

.actions-section .select-button:hover {
  background-color: #d86941;
}

/* Tablet Layout */
@media (min-width: 768px) and (max-width: 1023px) {
  .tablet-layout {
    display: flex;
    flex-grow: 1;
  }

  .miniatures-section {
    width: 120px;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    flex-wrap: nowrap;
    overflow-y: auto;
  }
  .miniature {
    width: 85px;
    height: 85px;
    margin: 5px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .tablet-main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .main-image-section {
    flex-grow: 1;
  }

  .actions-section {
    flex-direction: row;
  }
}

/* Desktop Layout */
@media (min-width: 1024px) {
  .figure-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desktop-layout {
    display: flex;
    height: 100%;
    max-width: var(--max-desktop-width);
    width: 100%;
  }

  .left-sidebar {
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: rgba(28, 36, 92, 0.8);
  }

  .categories-section {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 10px;
  }

  .category-button {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .category-button.active {
    border-left: 2px solid var(--secondary-color);
    border-bottom: none;
  }

  .miniatures-section {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 5px;
    justify-content: flex-start; 
    
    overflow-y: auto;
  }
  .miniature {
    width: 110px;
    height: 110px;
    margin: 5px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    }

  .main-content {
    flex-grow: 1;
    position: relative;
    max-width: calc(var(--max-desktop-width) - 400px);
  }

  .figure-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .right-sidebar {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: rgba(28, 36, 92, 0.8);
  }

  .actions-section {
    flex-direction: column;
    background-color: transparent;
  }

  .actions-section button {
    margin-bottom: 10px;
  }
}

/* Extra Large Screens */
@media (min-width: 1601px) {
  .figure-carousel.extra-large-screen {
    background-color: #1C245C;
  }

  .extra-large-screen .desktop-layout {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .figure-carousel * {
    transition: none !important;
  }
}