/* src/components/Sidebar.css */

.sidebar {
  display: flex;
  color: white;
  font-family: 'Roboto', sans-serif;
  background-color: #1C245C;
}

.sidebar-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.sidebar-title {
  font-size: 18px;
  font-family: 'Orbitron', sans-serif;
  margin: 0 10px;
}

.sidebar-buttons {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-button {
  padding: 8px 12px;
  margin: 5px;
  background-color: #D4A539;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Orbitron', sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-button:hover {
  background-color: #C4972C;
  transform: scale(1.05);
}

.sidebar-button:focus {
  outline: 2px solid #E97451;
  outline-offset: 2px;
}

/* Mobile and Tablet styles */
@media (max-width: 1023px) {
  .sidebar.mobile-tablet {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;
    overflow-x: auto;
  }

  .sidebar.mobile-tablet .sidebar-buttons {
    flex-grow: 1;
    justify-content: flex-end;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .sidebar.desktop {
    flex-direction: column;
    padding: 20px;
    width: 20%;
    max-width: 300px;
    height: 100vh;
  }

  .sidebar.desktop .sidebar-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .sidebar.desktop .sidebar-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .sidebar.desktop .sidebar-buttons {
    flex-direction: column;
  }

  .sidebar.desktop .sidebar-button {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .sidebar-button {
    transition: none;
  }
}