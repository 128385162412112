/* src/components/HistoryModal.css */

.history-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 36, 92, 0.95);
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 1000;
  overflow-y: auto;
}

.history-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: clamp(15px, 3vw, 30px);
  flex-grow: 1;
  font-family: 'Roboto', sans-serif;
  font-size: clamp(14px, 2vw, 18px);
  line-height: 1.6;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: clamp(10px, 2vw, 20px);
  position: relative;
  margin-bottom: 20px;
}

.modal-title {
  font-size: clamp(24px, 3vw, 32px);
  font-family: 'Orbitron', sans-serif;
  color: #D4A539;
  margin: 0;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #E97451;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  font-size: clamp(14px, 1.5vw, 18px);
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #d86941;
}

.clear-buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: clamp(10px, 2vw, 20px);
  margin-bottom: clamp(15px, 3vw, 30px);
  width: 100%;
}

.clear-button,
.clear-all-button,
.summary-button {
  padding: clamp(8px, 1.5vw, 12px) clamp(12px, 2vw, 24px);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  font-size: clamp(14px, 1.5vw, 18px);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.clear-button,
.clear-all-button {
  background-color: #D4A539;
  color: white;
}

.summary-button {
  background-color: #E97451;
  color: white;
}

.clear-button:hover,
.clear-all-button:hover,
.summary-button:hover {
  transform: scale(1.05);
}

.clear-button:hover,
.clear-all-button:hover {
  background-color: #C4972C;
}

.summary-button:hover {
  background-color: #d86941;
}

.summary-content,
.history-content {
  width: 100%;
  text-align: justify;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: clamp(15px, 3vw, 30px);
  margin-top: clamp(15px, 3vw, 30px);
}

.message {
  margin-bottom: clamp(10px, 2vw, 20px);
  padding: clamp(10px, 2vw, 20px);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile styles */
@media (max-width: 767px) {
  .history-modal-content {
    width: 95%;
    padding: 10px;
  }

  .modal-header {
    flex-direction: column;
    gap: 10px;
  }

  .close-button {
    position: static;
    margin-top: 10px;
  }

  .clear-buttons-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .history-modal-content {
    width: 90%;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .history-modal-content {
    width: 80%;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .clear-button,
  .clear-all-button,
  .summary-button,
  .close-button {
    transition: none;
  }
}

/* High contrast mode */
@media (forced-colors: active) {
  .clear-button,
  .clear-all-button,
  .summary-button,
  .close-button {
    border: 2px solid currentColor;
  }
}