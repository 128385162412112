/* src/styles/App.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Orbitron', sans-serif;
}

button {
  font-size: 16px;
}

.App {
  display: flex;
  height: 100vh;
  border: 2px solid #1C245C;
  border-radius: 10px;
}

/* Justified text styles for main content and history modal */
.main-content p, 
.main-content div:not(.no-justify), 
.main-content span:not(.no-justify),
.history-modal-content p,
.history-modal-content div:not(.no-justify),
.history-modal-content span:not(.no-justify) {
  text-align: justify;
  hyphens: auto;
  word-break: break-word;
}

/* Class for elements that shouldn't be justified */
.no-justify {
  text-align: left;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}