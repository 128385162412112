/* src/components/AboutModal.css */

.about-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 36, 92, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.about-modal-content {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.about-modal-content h2 {
  text-align: center;
  color: #D4A539;
  font-family: 'Orbitron', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.about-text {
  text-align: justify;
  font-size: 0.9rem;
  line-height: 1.6;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  background: #E97451;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #d86941;
}

.disclaimer {
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 15px;
  text-align: center;
  color: #D4A539;
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .about-modal-content {
    padding: 25px;
  }

  .about-modal-content h2 {
    font-size: 1.8rem;
  }

  .about-text {
    font-size: 1rem;
  }

  .close-button {
    padding: 10px 15px;
    font-size: 1rem;
  }

  .disclaimer {
    font-size: 0.9rem;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .about-modal-content {
    padding: 30px;
  }

  .about-modal-content h2 {
    font-size: 2rem;
  }

  .about-text {
    font-size: 1.1rem;
  }

  .close-button {
    padding: 12px 18px;
    font-size: 1.1rem;
  }

  .disclaimer {
    font-size: 1rem;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .close-button {
    transition: none;
  }
}