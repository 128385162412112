/* src/pages/HomePage.css */
:root {

  --max-desktop-width: 2250px;
}

* {
  box-sizing: border-box;
}


.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chatbox {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #e0e0e0;
}

.message-input {
  height: 60px;
  padding: 10px 50px;
  background-color:  #1C245C;
  border-top: 0px solid #ccc;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: #1C245C;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

/* Mobile and Tablet styles */
@media (max-width: 1023px) {
  .homepage.mobile-tablet {
    flex-direction: column;
  }

  .homepage.mobile-tablet .main-content {
    height: calc(100vh - 100px); /* Adjusted for new TopBar height */
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .homepage.mobile-tablet .main-content {
    /* Adjusted for smaller mobile TopBar */
  }
}
.homepage {
  display: flex;
  width: 100%;
  height: 99.5vh;
  overflow: hidden;
}

.message-input {
  height: 50px;
  padding: 10px 20px;
  background-color: #1C245C;
  border-top: 0px solid #ccc;
  margin-bottom: 20px;
}



/* Desktop styles */
@media (min-width: 1024px) {
  .homepage.desktop {
    flex-direction: row;
  }
  .message-input {
    height: 50px;
    padding: 10px 20px;
    background-color: #1C245C;
    border-top: 0px solid #ccc;
    margin-bottom: 0px;
  }
  .homepage {
    display: flex;
    width: 100%;
    height: 99.5vh;
    overflow: hidden;
  }
  .homepage.desktop .main-content {
    width: 80%;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .homepage * {
    transition: none !important;
  }
}