:root {
    --main-color: #E97451;
    --lang-color: #D4A539;
    --hover-color: #C4972C;
    --button-size: clamp(80px, 20vmin, 150px);
    --lang-button-size: clamp(50px, 12vmin, 100px);
    --circle-size: clamp(280px, 80vmin, 600px);
    --main-font-size: clamp(8px, 2.0vmin, 24px);
    --lang-font-size: clamp(5px, 1.4vmin, 24px);
  }
  
  .start-conversation-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .start-conversation-container {
    position: relative;
    width: var(--circle-size);
    height: var(--circle-size);
    max-width: 100vw;
    max-height: 100vh;
  }
  
  .main-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--button-size);
    height: var(--button-size);
    border-radius: 50%;
    background-color: var(--main-color);
    color: white;
    font-size: var(--main-font-size);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
  }
  
  .main-button:hover {
    background-color: #d86941;
  }
  
  .language-buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  
  .language-button {
    position: absolute;
    width: var(--lang-button-size);
    height: var(--lang-button-size);
    border-radius: 50%;
    background-color: var(--lang-color);
    color: white;
    border: none;
    font-size: var(--lang-font-size);
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0;
    transform: scale(0);
  }
  
  .show-languages .language-button.animate {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .language-button:hover {
    background-color: var(--hover-color);
  }
  
  @media (max-width: 480px) {
    .start-conversation-container {
      transform: scale(0.9);
    }
  }
  
  @media (min-width: 1600px) {
    :root {
      --circle-size: 70vmin;
      --button-size: 15vmin;
      --lang-button-size: 10vmin;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-button,
    .language-button,
    .language-buttons {
      transition: none !important;
    }
  }