.topbar {
    display: flex;
    background-color: #1C245C;
    color: white;
    font-family: 'Roboto', sans-serif;
    height: 120px; /* Consistent height for both mobile and tablet */
  }
  
  .topbar-image-container {
    height: 100%;
    width: auto;
    padding: 10px; /* Add some padding around the image */
  }
  
  .topbar-image {
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 10px; /* Rounded corners for the image */
  }
  
  .topbar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
  
  .topbar-title {
    font-size: 20px;
    font-family: 'Orbitron', sans-serif;
    margin: 0 0 10px 0;
    text-align: center;
  }
  
  .topbar-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .topbar-button {
    padding: 6px 10px; /* Slightly reduced padding */
    margin: 3px; /* Reduced margin */
    background-color: #D4A539;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Orbitron', sans-serif;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Prevent button text from wrapping */
  }
  
  .topbar-button:hover {
    background-color: #C4972C;
  }
  
  @media (max-width: 480px) {
    .topbar-title {
      font-size: 18px;
    }
  
    .topbar-button {
      padding: 5px 8px; /* Further reduced padding for very small screens */
      font-size: 12px;
    }
  }