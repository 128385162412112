:root {
    --color-background: rgba(20, 36, 92, 0.95);
    --color-text: white;
    --color-accent: #D4A539;
    --color-button: #E97451;
    --color-button-hover: #d86941;
    --font-main: 'Roboto', sans-serif;
    --font-accent: 'Orbitron', sans-serif;
    --content-max-width: 1600px;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: var(--color-background);
  }
  
  .legal-page {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    box-sizing: border-box;
    font-family: var(--font-main);
    color: var(--color-text);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
  
  .legal-content {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 2rem;
    width: 100%;
    max-width: var(--content-max-width);
    box-shadow: 0 0 20px rgba(212, 165, 57, 0.2);
    position: relative;
  }
  
  .legal-title {
    color: var(--color-accent);
    font-family: var(--font-accent);
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .legal-section {
    margin-bottom: 1.5rem;
  }
  
  .legal-section h2 {
    color: var(--color-accent);
    font-family: var(--font-accent);
    font-size: clamp(1rem, 3vw, 1.5rem);
    margin-bottom: 0.75rem;
  }
  
  .legal-section p {
    line-height: 1.6;
    font-size: clamp(0.875rem, 2vw, 1rem);
    text-align: justify;
    hyphens: auto;
  }
  .legal-section a {
    color: white; /* Set link color to white */
    text-decoration: underline;
  }
  .legal-section ul {
    font-size: 16px; /* Make bullet points the same size as the text */
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .back-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--color-button);
    color: var(--color-text);
    border: none;
    border-radius: 5px;
    font-family: var(--font-accent);
    font-size: clamp(0.75rem, 2vw, 1rem);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: var(--color-button-hover);
  }
  
  @media (max-width: 768px) {
    .legal-page {
      padding: 1rem;
    }
  
    .legal-content {
      padding: 1.5rem;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .back-button {
      transition: none;
    }
  }
  
  @supports (overflow: overlay) {
    .legal-page {
      overflow: overlay;
    }
  }