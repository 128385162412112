/* src/components/ChatBox.css */

.chatbox {
  flex: 1;
  background-color: #1C245C; 
  padding: 15px;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.message {
  padding: 12px;
  border-radius: 8px;
  word-break: break-word;
  margin: 5px 0;
  line-height: 1.5;
  white-space: pre-wrap;
  max-width: 80%;
}

.message.user {
  background-color: #E97451;
  text-align: left;
  align-self: flex-end;
}

.message.assistant {
  background-color: #2A3166;
  text-align: left;
  align-self: flex-start;
}

.message p {
  margin: 0 0 8px 0;
}

.message ul, .message ol {
  padding-left: 20px;
  list-style-position: outside;
}

.message ul li, .message ol li {
  margin-bottom: 5px;
  line-height: 1.5;
  display: list-item;
}

.message ul li::before {
  content: '\2022';
  color: white;
  display: inline-block;
  width: 1em;
  margin-right: 5px;
}

.message ol {
  counter-reset: item;
}

.message ol li::before {
  content: counter(item) ". ";
  counter-increment: item;
  color: white;
  display: inline-block;
  width: 1.5em;
  margin-right: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .chatbox {
    padding: 10px;
    font-size: 14px;
  }

  .message {
    padding: 10px;
    max-width: 90%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .chatbox {
    font-size: 15px;
  }

  .message {
    max-width: 85%;
  }
}

@media (min-width: 1025px) {
  .chatbox {
    padding: 20px;
    font-size: 18px;
  }

  .message {
    padding: 15px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .chatbox, .message {
    transition: none;
  }
}