/* src/pages/LoginPage.css */

:root {
  --fluid-typography-ratio: 0.5vw;
}

body {
  font-size: calc(14px + var(--fluid-typography-ratio));
}

.background {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgba(20, 36, 48, 255);
  background-size: 350% auto;
  background-position: center 55%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Orbitron', Arial, sans-serif;
  color: #fff;
  overflow: hidden;
  padding: 20px;
  transition: background-size 0.3s ease, background-position 0.3s ease;
}

.logo-container {
  position: absolute;
  top: 7%; /* Adjusted for mobile */
  right: 0%;
  z-index: 1;
}

.logo {
  width: clamp(120px, 35vw, 250px); /* Increased size for mobile */
  max-width: 100%;
  height: auto;
}

.headline {
  position: absolute;
  top: 18%; /* Adjusted for better visibility on mobile */
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Orbitron', sans-serif;
  font-size: clamp(2rem, 6vw + 1rem, 5rem); /* Increased size for mobile */
  letter-spacing: 0.2em;
  text-align: center;
  z-index: 2;
}

.headline-text {
  display: inline-block;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3),
               0 0 40px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.portal-container {
  position: absolute;
  top: 50%;
  left: 49.1%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.portal {
  width: clamp(140px, 22vw, 250px); /* Adjusted to be smaller on mobile */
  height: clamp(140px, 22vw, 250px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.portal-effect {
  position: absolute;
  width: 200%; /* Smaller on mobile */
  height: 200%;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(212, 165, 57, 0.2) 0%, rgba(212, 165, 57, 0) 70%);
  animation: pulse 2s infinite, rotate 15s linear infinite;
}

@keyframes pulse {
  0% { transform: scale(0.95); opacity: 0.7; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.95); opacity: 0.7; }
}

.portal::before, .portal::after {
  content: '';
  position: absolute;
  width: clamp(110%, 44vw, 500%); /* Adjusted to scale with screen size */
  height: clamp(110%, 44vw, 500%);
  border-radius: 50%;
  background: conic-gradient(from 0deg, rgba(212, 165, 57, 0.2), transparent 120deg, transparent 240deg, rgba(212, 165, 57, 0.2));
  animation: spin 5s linear infinite;
}

.portal::after {
  animation: spin 5s linear infinite reverse;
}

.portal.portal-revealed .portal-effect,
.portal.portal-revealed::before,
.portal.portal-revealed::after {
  animation: none !important;
  opacity: 0 !important;
  transition: opacity 0.5s ease-out !important;
}

.portal-revealed .login-form {
  opacity: 1;
  visibility: visible;
}

.login-form {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  width: clamp(180px, 60%, 280px); /* Reduced width for mobile */
  max-width: 90vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form label {
  margin-top: 1px;
  color: #ffffff;
  font-size: clamp(1rem, 3vw, 7rem);
}

.login-form input {
  width: 100%;
  padding: clamp(6px, 1.5vw, 20px);
  margin: 5px 0;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  font-size: clamp(20px, 1.5vw, 25px);
}

.form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

.form-actions button {
  padding: clamp(6px, 1.5vw, 12px) clamp(12px, 3vw, 24px);
  border: none;
  border-radius: 5px;
  background: #D4A539;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  font-size: clamp(16px, 1.5vw, 25px);
}

.form-actions button:hover {
  background: #C4972C;
  transform: scale(1.05);
}

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -5px;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #D4A539;
  font-size: clamp(1.5rem, 2vw, 2rem);
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gear-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.dropdown-toggle:hover .gear-icon,
.dropdown-toggle:focus .gear-icon {
  transform: rotate(90deg);
}

.dropdown-toggle:hover,
.dropdown-toggle:focus {
  color: #C4972C;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(28, 36, 92, 0.7);
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-content button {
  color: #D4A539;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: clamp(14px, 1.5vw, 16px);
}

.dropdown-content button:hover,
.dropdown-content button:focus {
  background-color: rgba(212, 165, 57, 0.2);
  color: #ffffff;
}

.error-message {
  color: #ff6b6b;
  margin-top: 10px;
  font-size: clamp(0.8rem, 1vw, 0.9rem);
}

.play-sound-button, #mute-button {
  position: absolute;
  padding: clamp(6px, 1vw, 8px);
  background: #D4A539;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: clamp(10px, 1.2vw, 14px);
}

.play-sound-button {
  top: 10px;
  left: 10px;
}

#mute-button {
  top: 10px;
  right: 10px;
}

.play-sound-button:hover, #mute-button:hover {
  background: #C4972C;
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .background {
    background-size: 230% auto;
  }

  .logo-container {
    top: 5%;
    right: 0%;
  }

  .logo {
    width: clamp(120px, 25vw, 200px);
  }

  .headline {
    top: 15%;
    font-size: clamp(2rem, 7vw + 1rem, 8rem);
  }

  .portal {
    width: clamp(200px, 30vw, 260px);
    height: clamp(200px, 30vw, 260px);
  }

  .login-form {
    width: clamp(180px, 75%, 250px);
  }

.portal::before, .portal::after {
  content: '';
  position: absolute;
  width: clamp(95.5%, 22vw, 500%); /* Adjusted to scale with screen size */
  height: clamp(95.5%, 22vw, 500%);
  border-radius: 50%;
  background: conic-gradient(from 0deg, rgba(212, 165, 57, 0.2), transparent 120deg, transparent 240deg, rgba(212, 165, 57, 0.2));
  animation: spin 5s linear infinite;
}
.portal-container {
  position: absolute;
  top: 50%;
  left: 49.3%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-form label {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
}

.login-form input {
  font-size: clamp(14px, 2.5vw, 25px);
}

.form-actions button {
  font-size: clamp(14px, 2.5rem, 20px);
}
.dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dropdown-toggle {
  font-size: 1.8rem;
}

.dropdown-content {
  right: 0;
  left: auto;
  transform: none;
}

}

/* Desktop styles */
@media (min-width: 1024px) and (max-width: 1599px) {
  .background {
    background-size: 100% auto;
  }

  .logo-container {
    top: 2%;
    right: 10%;
  }

  .logo {
    width: clamp(140px, 18vw, 200px);
  }

  .headline {
    top: 7%;
    font-size: clamp(2.5rem, 5vw + 1rem, 10rem);
  }

  .portal {
    width: clamp(80px, 8vw, 200px);
    height: clamp(80px, 8vw, 200px);
  }

  .portal-effect {
    width: 110%;
    height: 110%;
  }

  .portal::before, .portal::after {
    content: '';
    position: absolute;
    width: clamp(30%, 12.5vw, 200%);
    height: clamp(30%, 12.5vw, 200%);
    border-radius: 50%;
    background: conic-gradient(from 0deg, rgba(212, 165, 57, 0.2), transparent 120deg, transparent 240deg, rgba(212, 165, 57, 0.2));
    animation: spin 5s linear infinite;
  }

  .portal-container {
    position: absolute;
    top: 50%;
    left: 49.75%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .login-form {
    width: clamp(200px, 40%, 400px);
  }

  .login-form label {
    font-size: clamp(0.5rem, 1.25vw, 1.6rem);
  }

  .login-form input {
    font-size: clamp(16px, 1.25vw, 22px);
    padding: clamp(8px, 0.3vw, 14px);
  }

  .form-actions button {
    font-size: clamp(16px, 1.25vw, 20px);
    padding: clamp(8px, 0.3vw, 14px) clamp(20px, 3.5vw, 32px);
  }

  .dropdown-toggle {
    font-size: clamp(2.2rem, 5vw, 2.8rem);
  }

  .dropdown-content button {
    font-size: clamp(15px, 1.3vw, 18px);
    padding: 10px 14px;
  }
}

/* Large screen styles */
@media (min-width: 1600px) {
  .background {
    background-size: 80% auto;
  }

  .logo-container {
    top: 0%;
    right: 17%;
  }

  .logo {
    width: clamp(250px, 10vw, 500px);
  }

  .headline {
    top: 2%;
    font-size: clamp(3rem, 5vw + 1rem, 40rem);
  }

  .portal {
    width: clamp(50px, 8vw, 300px);
    height: clamp(50px, 8vw, 300px);
  }

  .portal-effect {
    width: 105%;
    height: 105%;
  }

  .portal::before, .portal::after {
    content: '';
    position: absolute;
    width: clamp(80%, 10vw, 450%);
    height: clamp(80%, 10vw, 450%);
    border-radius: 50%;
    background: conic-gradient(from 0deg, rgba(212, 165, 57, 0.2), transparent 120deg, transparent 240deg, rgba(212, 165, 57, 0.2));
    animation: spin 5s linear infinite;
  }

  .portal-container {
    position: absolute;
    top: 50%;
    left: 49.8%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .login-form {
    width: clamp(300px, 55%, 400px);
  }

  .login-form label {
    font-size: clamp(1.2rem, 2vw, 1.8rem);
  }

  .login-form input {
    font-size: clamp(18px, 2vw, 24px);
    padding: clamp(10px, 1.2vw, 16px);
  }

  .form-actions button {
    font-size: clamp(18px, 2vw, 22px);
    padding: clamp(12px, 1.5vw, 18px) clamp(24px, 3vw, 36px);
  }

  .dropdown-toggle {
    font-size: clamp(2.4rem, 4vw, 3rem);
  }

  .dropdown-content button {
    font-size: clamp(16px, 2vw, 20px);
    padding: 16px 20px;
  }
}

.landscape-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 36, 92, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.warning-text {
  color: #D4A539;
  font-family: 'Orbitron', sans-serif;
  font-size: clamp(1rem, 4vw, 1.5rem);
  text-align: center;
  padding: 0px;
  background-color: rgba(28, 36, 92, 1);
  border-radius: 10px;
  position: relative;
  z-index: 10001;
 
}

/* Media query for landscape orientation on smaller devices */
@media screen and (max-width: 1023px) and (orientation: landscape) {
  .landscape-warning {
    display: flex;
  }

  /* Hide the main content in landscape mode */
  .background {
    display: none;
  }
}

/* Add this at the end of your existing LoginPage.css file */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: rgba(28, 36, 92, 0.7);
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  text-align: center;
  color: #fff;
  font-family: 'Orbitron', sans-serif;
  box-shadow: 0 0 20px rgba(212, 165, 57, 0.5);
}

.popup-content.error {
  border: 2px solid #E97451;
}

.popup-content.info {
  border: 2px solid #D4A539;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-content button {
  background-color: #D4A539;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  transition: background-color 0.3s ease;
}

.popup-content button:hover {
  background-color: #C4972C;
}

.popup-content.error button {
  background-color: #E97451;
}

.popup-content.error button:hover {
  background-color: #d86941;
}

@media (max-width: 768px) {
  .popup-content {
    max-width: 90%;
    padding: 15px;
  }
}